import { Auth0ContextInterface } from '@auth0/auth0-react';
import { Button, H2 } from '@risk-first/ui-core';

export const Auth0LoginLogout: React.FC<{ auth0: Auth0ContextInterface }> = ({ auth0 }) => (
  <>
    <H2>Login / Logout</H2>
    {auth0.isAuthenticated ? (
      <Button size="regular" variant="default" onClick={() => auth0.logout({ returnTo: window.location.href })}>
        Logout
      </Button>
    ) : (
      <Button size="regular" variant="default" onClick={() => auth0.loginWithRedirect()}>
        Login
      </Button>
    )}
  </>
);
