import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { theme } from '../theme';
import { AppHome } from './pages/app-home/AppHome';
import { Home } from './pages/home/Home';
import { TenantHome } from './pages/tenant-home/TenantHome';

export const App = () => (
  <ThemeProvider theme={theme}>
    <Switch>
      <Route component={AppHome} exact={true} path="/:tenantId/:appId" />
      <Route component={TenantHome} exact={true} path="/:tenantId" />
      <Route component={Home} exact={true} path="/" />
    </Switch>
  </ThemeProvider>
);

export default App;
