import { H2 } from '@risk-first/ui-core';
import React from 'react';

interface StorageDebugProps {
  title: string;
  items: [string, string][];
}

export const StorageDebug: React.FC<StorageDebugProps> = ({ title, items }) => {
  return (
    <>
      <H2>{title}</H2>
      {items.length ? (
        <dl>
          {items.map(([name, value]) => (
            <React.Fragment key={name}>
              <dt>{name}</dt>
              <dd>{value}</dd>
            </React.Fragment>
          ))}
        </dl>
      ) : null}
    </>
  );
};
