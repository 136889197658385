export type Auth0Configuration = {
  clientId: string;
  domain: string;
  tenantId: string;
};

export const auth0Configurations: Auth0Configuration[] = [
  {
    clientId: 'Eg5IyC3ZAWI1XJKyAWsViThM14skcGJ1', // multi-tenant-test-1
    domain: 'dev-riskfirst.auth0.com',
    tenantId: 'tenant-1',
  },
  {
    clientId: 'YLyHwyGku2OQs6wmZJ6EEw2OEd3Dydd0', // multi-tenant-test-2
    domain: 'dev-riskfirst.auth0.com',
    tenantId: 'tenant-2',
  },
  {
    clientId: 'kMK84Iw8zQr6C5rlmzhprZ0uo4xSAWLB', // multi-tenant-test-3
    domain: 'dev-riskfirst.auth0.com',
    tenantId: 'tenant-3',
  },
];
