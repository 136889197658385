import { Dropdown, Option } from '@risk-first/ui-dropdown';
import { apps } from '../../data/apps';
import { useSelectedApp } from '../../hooks/use-selected-app';
import { useSelectedTenant } from '../../hooks/use-selected-tenant';

export const AppChooser: React.FC = () => {
  const selectedApp = useSelectedApp();
  const selectedTenant = useSelectedTenant();

  if (!selectedTenant) {
    throw new Error('No tenant selected');
  }

  return (
    <Dropdown
      label={selectedApp?.name ?? 'Select an application'}
      value={selectedApp?.id}
      variant="select"
      onChange={(selectedAppId: string) => {
        window.location.pathname = `/${selectedTenant?.id}/${selectedAppId}`;
      }}
    >
      {apps.map((app, index) => (
        <Option key={app.id} index={index} value={app.id}>
          {app.name}
        </Option>
      ))}
    </Dropdown>
  );
};
