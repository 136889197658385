import { H2 } from '@risk-first/ui-core';

export interface Auth0ConfigurationProps {
  audience?: string;
  clientId?: string;
}

export const Auth0Configuration: React.FC<Auth0ConfigurationProps> = ({ audience, clientId }) => (
  <>
    <H2>Auth0 Configuration</H2>
    <dl>
      <dt>Client ID</dt>
      <dd>{clientId}</dd>
      <dt>Audience</dt>
      <dd>{audience}</dd>
    </dl>
  </>
);
