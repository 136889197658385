import { useAuth0 } from '@auth0/auth0-react';
import { H1, H3 } from '@risk-first/ui-core';
import React, { useEffect, useState } from 'react';
import { AddCookie } from '../../components/add-cookie/AddCookie';
import { AddLocalStorageItem } from '../../components/add-local-storage-item/AddLocalStorageItem';
import { AddSessionStorageItem } from '../../components/add-session-storage-item/AddSessionStorageItem';
import { AppChooser } from '../../components/app-chooser/AppChooser';
import { Auth0Configuration } from '../../components/auth0-configuration/Auth0Configuration';
import { Auth0ContextInfo } from '../../components/auth0-context-info/Auth0ContextInfo';
import { Auth0LoginLogout } from '../../components/auth0-login-logout/Auth0LoginLogout';
import { StorageDebug } from '../../components/storage-debug/StorageDebug';
import { TenantChooser } from '../../components/tenant-chooser/TenantChooser';
import { useAppName } from '../../hooks/use-app-name';
import { useAuth0Configuration } from '../../hooks/use-auth0-configuration';
import { useSelectedTenant } from '../../hooks/use-selected-tenant';

const getCookieData = () => document.cookie.split('; ').filter(Boolean);

const getStorageData = (storage: Storage): [string, string][] => {
  const data: [string, string][] = [];

  for (let i = 0; i < storage.length; i++) {
    const key = storage.key(i) || '';
    const value = storage.getItem(key) || '';

    data.push([key, value]);
  }

  return data;
};

export const AppHome: React.FC = () => {
  const appName = useAppName();
  const auth0Configuration = useAuth0Configuration();
  const selectedTenant = useSelectedTenant();
  const auth0 = useAuth0();
  const [cookieData, setCookieData] = useState<string[]>(getCookieData());
  const handleCookieUpdate = () => {
    setCookieData(getCookieData());
  };
  const [localStorageData, setLocalStorageData] = useState<[string, string][]>([]);
  const handleLocalStorageUpdate = () => {
    setLocalStorageData(getStorageData(localStorage));
  };
  const [sessionStorageData, setSessionStorageData] = useState<[string, string][]>([]);
  const handleSessionStorageUpdate = () => {
    setSessionStorageData(getStorageData(sessionStorage));
  };
  const [accessToken, setAccessToken] = React.useState<string | null>(null);

  useEffect(() => {
    handleLocalStorageUpdate();
    handleSessionStorageUpdate();
  }, []); // Empty dependency array ensures this only runs once, similar to componentDidMount.

  useEffect(() => {
    if (auth0.isAuthenticated) {
      auth0.getAccessTokenSilently().then((token) => setAccessToken(token));
    }
  }, [auth0, auth0.isAuthenticated]);

  return (
    <>
      <H1>
        {selectedTenant?.name} - {appName}
      </H1>

      <dl>
        <dt>Select Tenant</dt>
        <dd>
          <TenantChooser />
        </dd>
      </dl>

      <dl>
        <dt>Select App</dt>
        <dd>
          <AppChooser />
        </dd>
      </dl>

      <Auth0Configuration audience={auth0Configuration?.audience} clientId={auth0Configuration?.clientId} />

      <Auth0ContextInfo
        accessToken={accessToken}
        isAuthenticated={auth0.isAuthenticated}
        isLoading={auth0.isLoading}
        user={auth0.user}
      />

      <Auth0LoginLogout auth0={auth0} />

      <StorageDebug
        items={cookieData.map((cookie) => {
          const [name, value] = cookie.split('=');

          return [name, value];
        })}
        title="Cookies"
      />

      <H3>Add Cookie</H3>
      <AddCookie onCookieAdded={handleCookieUpdate} />

      <StorageDebug items={localStorageData} title="Local Storage" />

      <H3>Add Local Storage Item</H3>
      <AddLocalStorageItem onItemAdded={handleLocalStorageUpdate} />

      <StorageDebug items={sessionStorageData} title="Session Storage" />

      <H3>Add Session Storage Item</H3>
      <AddSessionStorageItem onItemAdded={handleSessionStorageUpdate} />
    </>
  );
};
