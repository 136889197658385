import { useParams } from 'react-router-dom';

export const useAppName = () => {
  const { appId } = useParams<{ appId?: string }>();

  switch (appId) {
    case 'app-1':
      return 'App 1';
    case 'app-2':
      return 'App 2';
    default:
      return 'Unknown';
  }
};
