import { AppState, Auth0Provider } from '@auth0/auth0-react';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth0Configuration } from '../hooks/use-auth0-configuration';
import { isLocalhost } from '../utils/is-localhost';

interface Auth0ProviderWithHistoryProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithHistory: React.FC<Auth0ProviderWithHistoryProps> = ({ children }) => {
  const auth0Configuration = useAuth0Configuration();
  const history = useHistory();

  const onRedirectCallback = React.useCallback(
    (appState?: AppState) => {
      history.push(appState?.returnTo || window.location.pathname);
    },
    [history],
  );

  return (
    <Auth0Provider
      audience={auth0Configuration?.audience}
      cacheLocation="memory"
      clientId={auth0Configuration?.clientId ?? ''}
      cookieDomain={isLocalhost() ? undefined : '.pfaroe.net'}
      domain={auth0Configuration?.domain ?? ''}
      redirectUri={window.location.href}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
