import { Button } from '@risk-first/ui-core';
import { Input } from '@risk-first/ui-input';
import React, { useState } from 'react';

export interface AddLocalStorageItemProps {
  onItemAdded: () => void; // callback prop
}

export const AddLocalStorageItem: React.FC<AddLocalStorageItemProps> = (props) => {
  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (key && value) {
      localStorage.setItem(key, value);
      setKey('');
      setValue('');
      props.onItemAdded(); // notify parent component
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ margin: '0.5em 0' }}>
        <label htmlFor="localStorageKey">Local Storage Key:</label>
        <Input
          required
          id="localStorageKey"
          placeholder="Enter key"
          type="text"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
      </div>
      <div style={{ margin: '0.5em 0' }}>
        <label htmlFor="localStorageValue">Local Storage Value:</label>
        <Input
          required
          id="localStorageValue"
          placeholder="Enter value"
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <Button size="regular" type="submit" variant="default">
        Add to Local Storage
      </Button>
    </form>
  );
};
