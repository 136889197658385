import { Button } from '@risk-first/ui-core';
import { Input } from '@risk-first/ui-input';
import React, { useState } from 'react';

export interface AddSessionStorageItemProps {
  onItemAdded: () => void; // callback prop
}

export const AddSessionStorageItem: React.FC<AddSessionStorageItemProps> = (props) => {
  const [key, setKey] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (key && value) {
      sessionStorage.setItem(key, value);
      setKey('');
      setValue('');
      props.onItemAdded(); // notify parent component
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ margin: '0.5em 0' }}>
        <label htmlFor="sessionStorageKey">Session Storage Key:</label>
        <Input
          required
          id="sessionStorageKey"
          placeholder="Enter key"
          type="text"
          value={key}
          onChange={(e) => setKey(e.target.value)}
        />
      </div>
      <div style={{ margin: '0.5em 0' }}>
        <label htmlFor="sessionStorageValue">Session Storage Value:</label>
        <Input
          required
          id="sessionStorageValue"
          placeholder="Enter value"
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <Button size="regular" type="submit" variant="default">
        Add to Session Storage
      </Button>
    </form>
  );
};
