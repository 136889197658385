export type App = {
  audience: string;
  id: string;
  name: string;
};

export const apps: App[] = [
  { audience: 'https://app-1.pfaroe.net', id: 'app-1', name: 'App 1' },
  { audience: 'https://app-2.pfaroe.net', id: 'app-2', name: 'App 2' },
];
