import { useLocation } from 'react-router-dom';
import { tenants } from '../data/tenants';

export const useSelectedTenant = () => {
  const { pathname } = useLocation();

  if (pathname) {
    const parts = pathname.split('/').filter(Boolean);

    if (parts.length) {
      const tenantId = parts[0];

      return tenants.find((tenant) => tenant.id === tenantId);
    }
  }
};
