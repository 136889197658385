import { TenantChooser } from '../../components/tenant-chooser/TenantChooser';
import { App as Main, AppHeader } from './Home.style';

export const Home = () => (
  <Main>
    <AppHeader>
      <p style={{ margin: '1em 0' }}>
        Here we will have some mechanism to determine what tenant the user wants to access. For now, we will just use a
        dropdown.
      </p>
      <TenantChooser />
    </AppHeader>
  </Main>
);
