import { Button } from '@risk-first/ui-core';
import { Input } from '@risk-first/ui-input';
import Cookies from 'js-cookie';
import React, { useState } from 'react';

export interface AddCookieProps {
  onCookieAdded?: () => void;
}

export const AddCookie: React.FC<AddCookieProps> = ({ onCookieAdded }) => {
  const [name, setName] = useState<string>('');
  const [value, setValue] = useState<string>('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (name && value) {
      Cookies.set(name, value);
      setName('');
      setValue('');

      if (onCookieAdded) {
        onCookieAdded();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ margin: '0.5em 0' }}>
        <label htmlFor="cookieName">Cookie Name:</label>
        <Input
          required
          id="cookieName"
          placeholder="Enter cookie name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div style={{ margin: '0.5em 0' }}>
        <label htmlFor="cookieValue">Cookie Value:</label>
        <Input
          required
          id="cookieValue"
          placeholder="Enter cookie value"
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <Button size="regular" type="submit" variant="default">
        Add Cookie
      </Button>
    </form>
  );
};
