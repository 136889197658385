import { H2 } from '@risk-first/ui-core';

export interface Auth0ContextInfoProps {
  isAuthenticated: boolean;
  isLoading: boolean;
  user?: any; // Ideally, use a stricter type if you know the structure of the user
  accessToken?: string | null;
}

export const Auth0ContextInfo: React.FC<Auth0ContextInfoProps> = ({
  isAuthenticated,
  isLoading,
  user,
  accessToken,
}) => (
  <>
    <H2>Auth0 Context</H2>
    <dl>
      <dt>Is Authenticated</dt>
      <dd>{isAuthenticated ? 'Yes' : 'No'}</dd>
      <dt>Is Loading</dt>
      <dd>{isLoading ? 'Yes' : 'No'}</dd>
      {user ? (
        <>
          <dt>User</dt>
          <dd>
            <pre>{JSON.stringify(user, null, 2)}</pre>
          </dd>
        </>
      ) : null}
      {accessToken ? (
        <>
          <dt>Access Token</dt>
          <dd>{accessToken}</dd>
        </>
      ) : null}
    </dl>
  </>
);
