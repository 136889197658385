import styled from '@emotion/styled';

export const App = styled('div')`
  text-align: center;
`;

export const AppHeader = styled('header')`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 50%;
  min-height: 100vh;
`;
