import { auth0Configurations } from '../data/auth0-configurations';
import { useSelectedTenant } from '../hooks/use-selected-tenant';
import { useSelectedApp } from './use-selected-app';

export const useAuth0Configuration = () => {
  const selectedApp = useSelectedApp();
  const selectedTenant = useSelectedTenant();
  const selectedAuth0Configuration =
    selectedTenant && auth0Configurations.find(({ tenantId }) => selectedTenant?.id === tenantId);

  return (
    selectedAuth0Configuration && {
      audience: selectedApp?.audience,
      ...selectedAuth0Configuration,
    }
  );
};
