import { AppChooser } from '../../components/app-chooser/AppChooser';
import { App as Main, AppHeader } from './TenantHome.style';

export const TenantHome = () => {
  return (
    <>
      <Main>
        <AppHeader>
          <p style={{ margin: '1em 0' }}>
            Here we will have some mechanism to determine what app the user wants to access. For now, we will just use a
            dropdown.
          </p>
          <AppChooser />
        </AppHeader>
      </Main>
    </>
  );
};
